@import "../../../../../../styles/variables.scss";

.categories-step-container {
  .preset-tasks-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-height: 40vh;
    overflow-y: scroll;
    @include wemp-scrollbar;
    .preset-task-item {
      padding: 10px;
      cursor: pointer;
      border-radius: $border-radius;
      transition: 0.5s;
      &:hover {
        background: aliceblue;
      }
      &.selected {
        background: beige;
      }

      display: flex;
      align-items: center;
      gap: 15px;
      .preset-task-icon {
        height: 50px;
        width: 50px;
        border: 1px solid lightgrey;
        border-radius: 50%;
        padding: 10px;
      }
      .preset-task-info-wrapper {
        .preset-task-name {
          font-weight: 500;
        }
      }
    }
  }
}
@import "../../../styles/variables";

.companies-container {
  padding: 50px;
  .companies-filters-container {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .companies-table {
    margin-top: 25px;
  }
}
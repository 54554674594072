@import "../../styles/variables.scss";

.multiple-select {
  height: 50px;
  width: 100%;
  &.ant-select {
    .ant-select-selector {
      height: 50px;
      border-radius: $border-radius;
      .ant-select-selection-overflow {
        .ant-select-selection-overflow-item {
          .ant-select-selection-item {
            //height: 35px;
            //line-height: 35px;
            align-items: center;
            border-radius: 5px;
          }
        }
      }
    }
  }
}
.multiple-select-dropdown {
  .rc-virtual-list-scrollbar-thumb {
    opacity: 0.3;
    left: unset !important;
    right: 1px !important;
    width: 3px !important;
  }
  .ant-select-item {
    height: 40px;
    align-items: center;
  }
}

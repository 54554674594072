$breakpoints: (
  "xxl": 1366px,
  "xl": 1260px,
  "lg": 1048px,
  "md": 768px,
  "sm": 574px,
  "xs": 452px,
);

//SCREEN WIDTH MIXINS
@mixin xxl {
  @media screen and (max-width: map-get($breakpoints, "xxl")) {
    @content;
  }
}
@mixin xl {
  @media screen and (max-width: map-get($breakpoints, "xl")) {
    @content;
  }
}
@mixin lg {
  @media screen and (max-width: map-get($breakpoints, "lg")) {
    @content;
  }
}
@mixin md {
  @media screen and (max-width: map-get($breakpoints, "md")) {
    @content;
  }
}
@mixin sm {
  @media screen and (max-width: map-get($breakpoints, "sm")) {
    @content;
  }
}

@mixin xs {
  @media screen and (max-width: map-get($breakpoints, "xs")) {
    @content;
  }
}

//SCREEN HEIGHT MIXINS
@mixin sm-height {
  @media screen and (max-height: map-get($breakpoints, "sm")) {
    @content;
  }
}

@import "../../../../../styles/variables.scss";

.add-task-modal {
  .ant-modal-body {
    margin-top: 20px;
    .add-task-form {
      .add-task-input {
        height: 50px;
        border-radius: $border-radius;
      }
      .add-task-date {
        width: 100%;
        height: 50px;
        border-radius: $border-radius;
      }
      .add-task-time {
        width: 100%;
        height: 45px;
        border-radius: $border-radius;
      }
      .add-task-textarea {
        border-radius: $border-radius;
        resize: none;
      }
    }
  }
}

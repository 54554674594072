@import "../../../styles/variables";

.header {
  min-height: 64px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 50px !important;
  .logout-button {
    color: $color-white;
    font-size: 28px;
    cursor: pointer;
  }
}

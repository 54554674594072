@import "../../../../../styles/variables.scss";

.edit-doer-modal {
  .doers-wrapper {
    padding: 0;
    .doers-list-wrapper {
      height: unset;
    }
  }
}

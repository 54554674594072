@import "../../styles/variables.scss";

.doer-list-item {
  width: 100%;
  cursor: pointer;
  background: $color-white;
  border-radius: $border-radius;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  height: 78px;
  box-shadow: $box-shadow-item;
  margin-bottom: 15px;
  transition: 0.5s;
  &:hover {
    box-shadow: 0 0 16px -6px $color-blue;
  }

  .list-item-left-wrapper {
    display: flex;
    align-items: center;
    gap: 15px;

    .doer-image {
      width: 64px;
      height: 64px;
      object-fit: cover;
      border-radius: 50%;
      flex-shrink: 0;
    }

    .doer-name-wrapper {
      display: flex;
      align-items: center;
      gap: 5px;
      .doer-name {
        font-weight: 500;
        font-size: $font-size;
        color: $color-dark;
      }
      .doer-verified-icon {
        color: $color-green;
      }
    }

    .doer-categories {
      color: $color-grey;
      opacity: 0.8;
      @include ellipsis-one-line;
    }
  }

  .list-item-right-wrapper {
    text-align: end;

    .doer-available-days {
      font-weight: 500;
      color: $color-dark;
      white-space: nowrap;
    }

    .doer-phone-number {
      color: $color-grey;
      white-space: nowrap;
    }
  }
}

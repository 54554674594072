@import "../../../../styles/variables.scss";

.business-task-details-wrapper {
  .business-task-info {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 25px;
    .company-name-wrapper {
      font-size: $font-size-xl;
      font-weight: 600;
      display: flex;
      align-items: center;
      gap: 15px;
      margin-bottom: 8px;
      .company-name {
        color: $color-dark;
      }
    }
    .business-task-info-wrapper {
      .info-title {
        font-size: 17px;
        opacity: 0.9;
        font-weight: 500;
      }
      .section {
        margin-top: 25px;
        margin-bottom: 20px;
        .section-content {
          margin-top: 10px;
        }
        .business-task-description {
          font-size: 15px;
        }
      }
    }
    .action-buttons-wrapper {
      display: flex;
      flex-direction: column;
      gap: 12px;
      .action-button {
        width: 100%;
        border-radius: $border-radius;
        height: 50px;
        min-width: 100px;
        &.red {
          border-color: $color-red;
          color: $color-red;
          box-shadow: 0 0 14px -8px rgb(255, 49, 0, 70%);
        }
        &.green {
          border-color: $color-green;
          color: $color-green;
          box-shadow: 0 0 14px -8px rgb(6, 214, 114, 70%);
        }
        &.blue {
          border-color: $color-blue;
          color: $color-blue;
          box-shadow: 0 0 14px -8px rgb(55, 166, 247, 70%);
        }
      }
    }
  }
}
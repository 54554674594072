@import "../../../styles/variables.scss";
@import "../../../styles/responsive.scss";

.reviews-container {
  padding: 50px;
  .add-review-container {
    display: flex;
    margin-bottom: 25px;
    .review-status-select {
      width: 300px;
    }
  }
  .reviews-list-wrapper {
    height: calc(100vh - 164px - 52px - 70px);
    background: white;
    border-radius: $border-radius;
    box-shadow: $box-shadow-container;
    padding: 30px;
    overflow-y: auto;
    @include wemp-scrollbar;
    &.loading {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .reviews-list-pagination {
    margin-top: 20px;
    text-align: center;
  }
}

@import "../../../styles/variables.scss";

.business-tasks-container {
  padding: 50px;
  .statuses-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;
    gap: 10px;
  }
  .filters-container {
    display: flex;
    gap: 10px;
    margin-bottom: 25px;
    .search-input {
      height: 50px;
      border-radius: $border-radius;
      font-size: $font-size;
    }
  }
  .business-tasks-table {
    .ant-table-row {
      &:hover {
        cursor: pointer;
      }
    }
  }
}
@import "../../../../styles/variables.scss";

.doers-list-content-wrapper {
  .doers-list-filters-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
    gap: 10px;
    .search-input {
      height: 50px;
      border-radius: $border-radius;
      font-size: $font-size;
    }
  }
}

@import "../../../../../styles/variables.scss";

.reject-task-modal {
  max-width: 450px;
  .reject-task-modal-content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;
    .modal-icon-wrapper {
      font-size: 84px;
    }
    .modal-confirmation-text {
      text-align: center;
    }
  }
  .reject-radio-group {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}

@import "../../../styles/variables";

.sidebar {
  min-height: 100vh;
  .logo-container {
    height: 60px;
    padding-left: 15px;
    padding-right: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    > svg {
      max-width: 120px;
    }
  }
  .sidebar-menu {
    .ant-menu-item {
      height: 60px;
      display: flex;
      align-items: center;
      &:active {
        background-color: $color-primary !important;
      }
      &.ant-menu-item-selected {
        background-color: $color-primary;
        font-weight: 500;
      }
      .ant-menu-item-icon {
        font-size: $font-size-xl;
      }
    }
  }
}

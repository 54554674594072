.with-edit {
  display: flex;
  gap: 5px;
  width: fit-content;
  padding: 4px 8px;
  margin-left: -8px;
  transition: 0.2s;
  .edit-icon {
    font-size: 18px;
    opacity: 0;
    transition: 0.2s;
    cursor: pointer;
    margin-left: 8px;
  }
  &:hover {
    background: rgba(101, 101, 101, 0.1);
    border-radius: 8px;
    .edit-icon {
      opacity: 1;
    }
  }
}

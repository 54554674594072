@import "../../../../../styles/variables.scss";

.add-doer-modal {
  .ant-modal-body {
    padding: 16px 24px 0;
    .add-doer-form {
      .ant-form-item {
        &.doer-radio-form-item {
          margin-bottom: 10px;
        }
        .add-doer-input {
          height: 45px;
          border-radius: $border-radius;
        }
        .add-doer-radio-group {
          display: flex;
          align-items: center;
          gap: 5px;
          justify-content: space-between;
        }

        .add-doer-textarea {
          border-radius: $border-radius;
          resize: none;
        }
      }
    }
  }
}

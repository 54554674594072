@import "./styles/responsive.scss";
@import "./styles/variables.scss";

* {
  box-sizing: border-box;
}

//for cometchat iframe
iframe {
  pointer-events: none;
}

.ant-modal-wrap {
  margin-left: 200px;
  .ant-modal {
    //top: 150px;
    //top: 50%;
    //transform: translate(0%, -50%);
    .ant-modal-content {
      border-radius: $border-radius-lg;
      .ant-modal-header {
        border-top-left-radius: $border-radius-lg;
        border-top-right-radius: $border-radius-lg;
        .ant-modal-title {
          font-size: $font-size-lg;
        }
      }
      .ant-modal-footer {
        height: 65px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .ant-btn {
          height: 40px;
          border-radius: $border-radius;
          font-weight: 500;
          font-size: 14px;
        }
      }
    }
  }
}

@include md {
  .ant-modal-wrap {
    margin-left: unset;
  }
}

@import "../../../styles/variables.scss";

.doers-wrapper {
  padding: 50px;
  .doers-list-filters-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
    gap: 10px;
    .search-input {
      height: 50px;
      border-radius: $border-radius;
      font-size: $font-size;
    }
    .add-new-doer-button {
      border: none;
      cursor: pointer;
      background: $color-primary;
      color: $color-white;
      font-size: $font-size-lg;
      font-weight: bold;
      height: 40px;
      min-width: 40px;
      width: 40px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .doers-list-wrapper {
    height: calc(100vh - 164px - 52px - 75px);
    background: white;
    border-radius: $border-radius;
    box-shadow: 0 0 65px -25px rgb(0 21 41 / 60%);
    padding: 30px;
    overflow-y: auto;
    @include wemp-scrollbar;
    &.loading {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .doers-list-pagination {
    margin-top: 20px;
    text-align: center;
  }
}

@import "../../styles/variables.scss";

.custom-add-button {
  border: none;
  cursor: pointer;
  background: $color-primary;
  color: $color-white;
  font-size: $font-size-lg;
  font-weight: bold;
  height: 40px;
  min-width: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover,
  &:focus {
    background: $color-primary;
    color: $color-white;
    opacity: 0.8;
  }
}

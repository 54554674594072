@import "../../../../styles/variables.scss";

.task-details-wrapper {
  .task-info-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 25px;
    .task-main-info-wrapper {
      display: flex;
      align-items: center;
      gap: 20px;
      .task-image {
        height: 164px;
        width: 164px;
        border-radius: 50%;
        object-fit: cover;
        box-shadow: $box-shadow-item;
      }
      > .anticon {
        font-size: 54px;
        padding: 50px;
        border-width: 5px;
        border-style: solid;
        border-radius: 50%;
        box-shadow: $box-shadow-item;
      }
      .task-info-details-wrapper {
        .client-name-wrapper {
          font-size: $font-size-xl;
          font-weight: 600;
          display: flex;
          align-items: center;
          gap: 15px;
          .swap-icon {
            font-size: 24px;
          }
          .client-name {
            color: $color-dark;
          }
        }
        .task-info-title {
          font-size: 17px;
          opacity: 0.9;
          font-weight: 500;
        }
        .cancellation-reason-wrapper {
          margin-top: 5px;
          margin-bottom: 5px;
          display: flex;
          align-items: center;
          gap: 5px;
          .cancellation-reason-text {
            color: $color-red;
            font-weight: 600;
          }
        }
        .task-details {
          .task-info-detail {
            display: flex;
            align-items: center;
            gap: 5px;
          }
        }
      }
    }
    .task-action-buttons-wrapper {
      display: flex;
      flex-direction: column;
      gap: 12px;
      .action-button {
        width: 100%;
        border-radius: $border-radius;
        height: 50px;
        min-width: 100px;
        &.red {
          border-color: $color-red;
          color: $color-red;
          box-shadow: 0 0 14px -8px rgb(255, 49, 0, 70%);
        }
        &.green {
          border-color: $color-green;
          color: $color-green;
          box-shadow: 0 0 14px -8px rgb(6, 214, 114, 70%);
        }
        &.blue {
          border-color: $color-blue;
          color: $color-blue;
          box-shadow: 0 0 14px -8px rgb(55, 166, 247, 70%);
        }
      }
    }
  }
  .section-title-with-edit {
    margin-bottom: 6px;
  }
  .task-description-wrapper {
    margin-bottom: 20px;
    .task-description {
      font-size: 15px;
      margin-bottom: 20px;
    }
    .task-images-wrapper {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 12px;
      .task-image-wrapper {
        height: 200px;
        .task-image {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
    .insurance-policy-code {
      .unison-active-status {
        margin-left: 5px;
        color: $color-green;
        font-weight: 500;
      }
    }
  }
}

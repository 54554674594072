@import "../../styles/variables.scss";

.auth-layout {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
  background: $color-dark;
  .auth-layout-form {
    max-width: 500px;
    width: 100%;
    padding: 30px;
    background: $color-white;
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 0 9px 5px rgba(239, 54, 83, 0.45);

    > svg {
      margin-bottom: 30px;
    }

    .ant-form-item {
      width: 100%;

      .auth-input {
        height: 50px;
        border-radius: $border-radius;

        &[type="number"] {
          &::-webkit-inner-spin-button,
          &::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
        }
      }
    }

    .send-otp-button {
      border: none;
      width: 100%;
      border-radius: $border-radius;
      height: 45px;
      background: $color-primary;
      color: $color-white;
      margin-bottom: 16px;
    }

    .remember-me-checkbox {
      align-self: start;
      color: $color-dark;
    }
  }
}

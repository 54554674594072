@import "../../../../styles/variables.scss";

.task-details-container {
  padding: 50px;
  .task-details-main-wrapper {
    height: calc(100vh - 164px);
    background: white;
    border-radius: $border-radius;
    box-shadow: $box-shadow-container;
    padding: 30px;
    overflow-y: auto;
    @include wemp-scrollbar;

    &.loading {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

@import "../../../../../styles/variables.scss";

.find-doer-modal {
  .ant-modal-body {
    .find-modal-content-wrapper {
      .find-doer-checkbox-group {
        display: flex;
        flex-direction: column;
        gap: 10px;
      }
      .doers-details-wrapper {
        max-height: 520px;
        .doers-wrapper {
          padding: 0;

          .doers-list-wrapper {
            height: 432px;
          }
        }
      }
      .doer-details-wrapper {
        max-height: 520px;
        overflow-y: auto;
        @include wemp-scrollbar;
        .doer-container {
          padding: 0;

          .doer-page-wrapper {
            height: unset;
          }
        }
      }
    }
  }
  .ant-modal-footer {
    .find-doer-footer-button {
      border-color: $color-blue;
      color: $color-blue;
    }
  }
}

@import "../../../../../styles/variables.scss";

.edit-about-me-modal {
  .edit-doer-textarea {
    &.ant-input {
      border-radius: $border-radius;
      resize: none;
    }
  }
}

@import "../../../../styles/variables.scss";
@import "../../../../styles/responsive.scss";

.review-item-container {
  width: 100%;
  background: $color-white;
  border-radius: $border-radius;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  box-shadow: $box-shadow-item;
  margin-bottom: 15px;
  transition: 0.5s;

  &:hover {
    box-shadow: 0 0 16px -6px $color-blue;
  }
  .review-item-left-wrapper {
    display: flex;
    gap: 8px;
    .reviewer-image-wrapper {
      .reviewer-image {
        margin-top: 2px;
        width: 40px;
        height: 40px;
        object-fit: cover;
        border-radius: 50%;
        flex-shrink: 0;
      }
    }

    .review-details-wrapper {
      .reviewer-name-wrapper {
        display: flex;
        align-items: center;
        gap: 8px;

        .reviewer-name {
          color: #001529;
          font-size: 16px;
          font-weight: 500;
        }
      }
      .reviewer-comment {
        color: $color-grey;
      }
    }
  }
  .review-right-wrapper {
    display: flex;
    align-items: center;
    gap: 12px;
    .anticon {
      font-size: 22px;
      font-weight: 500;
      cursor: pointer;
      &.no-icon {
        color: $color-red;
      }
      &.yes-icon {
        color: $color-green;
      }
    }
  }
}

@import "../../../styles/variables.scss";
@import "../../../styles/responsive.scss";

.tasks-wrapper {
  padding: 50px;
  .statuses-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;
    gap: 10px;
    .add-new-task-button {
      border: none;
      cursor: pointer;
      background: $color-primary;
      color: $color-white;
      font-size: $font-size-lg;
      font-weight: bold;
      height: 40px;
      min-width: 40px;
      width: 40px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .tasks-list-wrapper {
    height: calc(100vh - 253px - 52px);
    background: white;
    border-radius: $border-radius;
    box-shadow: $box-shadow-container;
    padding: 30px;
    overflow-y: auto;
    @include wemp-scrollbar;

    &.loading {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .tasks-list-pagination {
    margin-top: 20px;
    text-align: center;
  }
}

@include xl {
  .tasks-wrapper {
    .statuses-wrapper {
      flex-wrap: wrap;
    }
  }
}

@import "../../../../../styles/variables.scss";

.edit-description-modal {
  .edit-task-textarea {
    &.ant-input {
      border-radius: $border-radius;
      resize: none;
    }
  }
}

@import "../../styles/variables.scss";
.task-wrapper {
  padding: 10px;
  border-radius: $border-radius;
  background: $color-white;
  box-shadow: $box-shadow-item;
  min-height: 64px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  gap: 15px;
  cursor: pointer;
  transition: 0.5s;
  &:hover {
    box-shadow: 0 0 16px -6px $color-blue;
  }
  .task-status-icon-wrapper {
    .anticon {
      font-size: 24px;
    }
  }
  .task-image {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    object-fit: cover;
    box-shadow: $box-shadow-item;
  }
  .task-info-wrapper {
    width: 100%;
    .task-main-info-wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .task-main-info-left-wrapper {
        display: flex;
        align-items: center;
        gap: 10px;

        .client-name-wrapper {
          display: flex;
          align-items: center;
          gap: 5px;
          .client-name {
            color: $color-dark;
            font-size: $font-size;
            font-weight: 500;
          }
          .task-specified-status {
            color: $color-red;
            font-weight: 500;
          }
        }

        .client-contact-wrapper {
          display: flex;
          align-items: center;
          gap: 5px;
        }
      }

      .task-main-info-right-wrapper {
        display: flex;
        align-items: center;
        gap: 5px;
        font-weight: 500;
        font-size: $font-size;

        .anticon {
          font-size: 24px;
        }
      }
    }

    .task-title-wrapper {
      .task-title {
        color: $color-grey;
        font-weight: 500;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        line-height: 1.3rem;
        max-height: 5rem;
        -webkit-line-clamp: 2;
      }
    }

    .task-description-wrapper {
      .task-description {
        color: $color-grey;
        opacity: 0.8;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        line-height: 1.3rem;
        max-height: 5rem;
        -webkit-line-clamp: 2;
      }
    }
  }
}

@import "../../../../../styles/variables.scss";

.edit-business-task-description-modal {
  .ant-modal-title {
    margin-bottom: 20px;
  }
  .ant-input {
    border-radius: $border-radius;
    resize: none;
  }
  textarea {
    @include wemp-scrollbar;
  }
}
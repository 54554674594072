@import "../../../../../styles/variables.scss";

.doer-wrapper {
  .doer-info-wrapper {
    display: flex;
    gap: 20px;
    align-items: center;
    margin-bottom: 20px;
    .doer-image {
      width: 164px;
      height: 164px;
      object-fit: cover;
      border-radius: 50%;
      flex-shrink: 0;
      box-shadow: $box-shadow-item;
    }
    .doer-main-info {
      .doer-name-wrapper {
        display: flex;
        align-items: center;
        gap: 8px;
        .doer-name {
          color: $color-dark;
          font-size: $font-size-xl;
          font-weight: 600;
        }
        .doer-verified-icon {
          color: $color-green;
        }
        .doer-activity-status {
          cursor: pointer;
          width: 10px;
          height: 10px;
          border-radius: 50%;
        }
      }
      .doer-info {
        display: flex;
        gap: 10px;
        align-items: center;
      }
    }
  }
  .doer-categories-section-title {
    margin-bottom: 6px;
    font-size: 19px;
  }
  .doer-categories-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 8px;
    margin-bottom: 15px;
    .category-item {
      padding: 10px;
      border-radius: 20px;
      box-shadow: $box-shadow-item;
    }
  }
  .section-wrapper {
    margin-bottom: 15px;
  }
}

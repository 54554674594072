@import "../../styles/variables.scss";

.internal-comment {
  display: flex;
  align-items: center;
  gap: 10px;
  min-height: 64px;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: $border-radius;
  box-shadow: 0 0 14px -6px rgba(0, 21, 41, 0.86);
  .anticon {
    font-size: $font-size-lg;
    color: $color-grey;
    margin-left: 5px;
  }
  .internal-comment-author {
    font-weight: 500;
  }
}

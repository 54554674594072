//COLORS
$color-primary: #ef3653;
$color-white: #ffffff;
$color-background: #f0f2f4;
$color-dark: #001529;
$color-green: #06d672;
$color-grey: #617585;
$color-blue: #37a6f7;
$color-red: #ff3100;
$color-yellow: #f9c922;

//FONT SIZES
$font-size-xxs: 11px;
$font-size-xs: 12px;
$font-size-sm: 14px;
$font-size: 16px;
$font-size-lg: 18px;
$font-size-xl: 20px;
$font-size-xxl: 24px;

//BORDER RADIUS
$border-radius: 8px;
$border-radius-lg: 12px;

//BOX SHADOW
$box-shadow-item: 0 0 14px -6px rgba(0, 21, 41, 0.86);
$box-shadow-container: 0 0 65px -25px rgb(0, 21, 41, 0.6);

//SCROLLBAR
@mixin wemp-scrollbar {
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(104, 122, 158, 0.25);
    border-radius: $border-radius;
  }

  &::-webkit-scrollbar-track {
    background: rgba(104, 122, 158, 0.25);
    border-radius: $border-radius;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: rgba(104, 122, 158, 0.5);
  }
}

//ONE LINE ELLIPSIS
@mixin ellipsis-one-line {
  min-height: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  line-height: 1.3rem;
  max-height: 2.6rem;
  -webkit-line-clamp: 1;
}

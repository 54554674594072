@import "../../../styles/variables.scss";

.find-doer-skills-wrapper {
  max-height: 520px;
  overflow-y: auto;
  @include wemp-scrollbar;
  .find-doer-checkbox-group {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}
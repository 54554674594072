.add-company-modal {
  .add-company-subtitle {
    margin-top: 24px;
    margin-bottom: 24px;
  }
  .ant-modal-footer {
    height: 35px !important;
    position: relative;
  }
  .buttons-wrapper {
    position: absolute;
    right: 24px;
    bottom: 20px;
    z-index: 1;
    .next-button {
      height: 40px;
      border-radius: 8px;
      font-weight: 500;
      font-size: 14px;
    }
  }
}
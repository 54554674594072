@import "../../styles/variables.scss";

.section-title {
  display: flex;
  gap: 10px;
  align-items: center;
  font-size: $font-size-lg;
  font-weight: 500;
  //margin-bottom: 10px;
  > .anticon {
    cursor: pointer;
    font-size: $font-size-xl;
  }
  .section-title-logo {
    height: 35px;
    width: 35px;
  }
}

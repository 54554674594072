@import "../../styles/variables.scss";

.task-status-button {
  width: 100%;
  text-align: center;
  border-radius: $border-radius;
  height: 64px;
  font-size: $font-size-lg;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
  border-color: #d9d9d9;
}
@import "../../styles/variables.scss";

.custom-select {
  &.ant-select {
    width: 100%;
    height: 50px;
    .ant-select-selector {
      border-radius: $border-radius;
      height: 50px;
      .ant-select-selection-item,
      .ant-select-selection-placeholder {
        display: flex;
        align-items: center;
      }
    }
  }
}
